

import { Fragment, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Dialog, Popover, Tab, Transition, Menu } from '@headlessui/react';
import { MenuIcon, ShoppingBagIcon, XIcon, BellIcon } from '@heroicons/react/outline';
import {
    CashIcon,
    CheckCircleIcon,
    ChevronDownIcon,
    ChevronRightIcon,
    OfficeBuildingIcon,
    SearchIcon,
} from '@heroicons/react/solid';

import { AuthService } from './../common/auth.service';

import { classNames } from './../libraries/utility';

import { Menu as TopMenu } from './menu';

import { Search } from './search';
import { Cart } from './cart';
import { Location } from './location';
import { Notification } from './notification';

import { navigation } from '../data/navigation';
import { DeliveryLocation } from '../common/deliveryLocation';

export function NavBar() {
	const _delivery_location = DeliveryLocation();

    const [open, setOpen] = useState(false);

	const [currentUser, setCurrentUser] = useState(null);

	useEffect(() => {
		if(AuthService.currentUserValue)
		{
			var _user = JSON.parse(AuthService.currentUserValue);
			setCurrentUser(_user);
		}
	},[]);

    return (
        <>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 flex z-40 lg:hidden" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <div className="relative max-w-xs w-full bg-white shadow-xl pb-12 flex flex-col overflow-y-auto">
                            <div className="px-4 pt-5 pb-2 flex">
                                <button
                                    type="button"
                                    className="-m-2 p-2 rounded-md inline-flex items-center justify-center text-gray-400"
                                    onClick={() => setOpen(false)}
                                >
                                    <span className="sr-only">Close menu</span>
                                    <XIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                            </div>

                            {/* Links */}
                            <Tab.Group as="div" className="mt-2">
                                <div className="border-b border-gray-200">
                                    <Tab.List className="-mb-px flex px-4 space-x-8">
                                        {navigation.categories.map((category) => (
                                            <Tab
                                                key={category.name}
                                                className={({ selected }) =>
                                                    classNames(
                                                        selected ? 'text-amber-600 border-amber-600' : 'text-gray-900 border-transparent',
                                                        'flex-1 whitespace-nowrap py-4 px-1 border-b-2 text-base font-medium'
                                                    )
                                                }
                                            >
                                                {category.name}
                                            </Tab>
                                        ))}
                                    </Tab.List>
                                </div>
                                <Tab.Panels as={Fragment}>
                                    {navigation.categories.map((category) => (
                                        <Tab.Panel key={category.name} className="pt-10 pb-8 px-4 space-y-10">
                                            <div className="grid grid-cols-2 gap-x-4">
                                                {category.featured.map((item) => (
                                                    <div key={item.name} className="group relative text-sm">
                                                        <div className="aspect-w-1 aspect-h-1 rounded-lg bg-gray-100 overflow-hidden group-hover:opacity-75">
                                                            <img src={item.imageSrc} alt={item.imageAlt} className="object-center object-cover" />
                                                        </div>
                                                        <a href={item.href} className="mt-6 block font-medium text-gray-900">
                                                            <span className="absolute z-10 inset-0" aria-hidden="true" />
                                                            {item.name}
                                                        </a>
                                                        <p aria-hidden="true" className="mt-1">
                                                            Shop now
                                                        </p>
                                                    </div>
                                                ))}
                                            </div>
                                            {category.sections.map((section) => (
                                                <div key={section.name}>
                                                    <p id={`${category.id}-${section.id}-heading-mobile`} className="font-medium text-gray-900">
                                                        {section.name}
                                                    </p>
                                                    <ul
                                                        role="list"
                                                        aria-labelledby={`${category.id}-${section.id}-heading-mobile`}
                                                        className="mt-6 flex flex-col space-y-6"
                                                    >
                                                        {section.items.map((item) => (
                                                            <li key={item.name} className="flow-root">
                                                                <Link to={item.href} className="-m-2 p-2 block text-gray-500">
                                                                    {item.name}
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            ))}
                                        </Tab.Panel>
                                    ))}
                                </Tab.Panels>
                            </Tab.Group>

                            <div className="border-t border-gray-200 py-6 px-4 space-y-6">
                                {navigation.pages.map((page) => (
                                    <div key={page.name} className="flow-root">
                                        <a href={page.href} className="-m-2 p-2 block font-medium text-gray-900">
                                            {page.name}
                                        </a>
                                    </div>
                                ))}
                            </div>

                            <div className="border-t border-gray-200 py-6 px-4 space-y-6">
                                <div className="flow-root">
									<Link to="/guest/login" className="-m-2 p-2 block font-medium text-gray-900">
                                        Sign in
                                    </Link>
                                </div>
                                <div className="flow-root">
									<Link to="/guest/register" className="-m-2 p-2 block font-medium text-gray-900">
                                        Create account
                                    </Link>
                                </div>
                            </div>

                            <div className="border-t border-gray-200 py-6 px-4">
                                <a href="#" className="-m-2 p-2 flex items-center">
                                    <img
                                        src="https://tailwindui.com/img/flags/flag-canada.svg"
                                        alt=""
                                        className="w-5 h-auto block flex-shrink-0"
                                    />
                                    <span className="ml-3 block text-base font-medium text-gray-900">CAD</span>
                                    <span className="sr-only">, change currency</span>
                                </a>
                            </div>
                        </div>
                    </Transition.Child>
                </Dialog>
            </Transition.Root>

            <header className="fixed top-0 left-0 right-0 bg-white z-10">
                {/* <p className="bg-amber-600 h-10 flex items-center justify-center text-sm font-medium text-white px-4 sm:px-6 lg:px-8">
                    Get free delivery on orders over $100
                </p> */}
				{/* <p className="bg-red-600 h-10 flex items-center justify-center text-sm font-medium text-white px-4 sm:px-6 lg:px-8">
                    We're currently updating adott experience for you. please use &nbsp;<a href="http://old.adott.ca" className="underline">old.adott.ca</a>&nbsp;meanwhile.
                </p> */}

                <nav aria-label="Top" className="max-w-full mx-auto">
                    <div className="border-b border-gray-200 px-4 sm:px-6 lg:px-8">
                        <div className="h-16 flex items-center">
                            <button
                                type="button"
                                className="bg-white p-2 rounded-md text-gray-400 lg:hidden"
                                onClick={() => setOpen(true)}
                            >
                                <span className="sr-only">Open menu</span>
                                <MenuIcon className="h-6 w-6" aria-hidden="true" />
                            </button>

                            {/* Logo */}
                            <div className="ml-4 flex lg:ml-0">
                                <Link to="/">
                                    <span className="sr-only">Adott</span>
                                    <img
                                        className="h-12 w-auto"
                                        src={"/logo.svg"}
                                        alt=""
                                    />
                                </Link>
                            </div>

                            <TopMenu/>

                            <div className="ml-auto flex items-center">

                                {AuthService.currentUserValue != null ?
                                    <>
                                        {/* Account */}
                                        <Menu as="div" className="ml-3 relative">
                                            <div>
                                                <Menu.Button className="max-w-xs bg-white rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 lg:p-2 lg:rounded-md lg:hover:bg-gray-50">
													 <span className="inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-500">
														<span className="text-xs font-medium leading-none text-white">{ currentUser?.first_name[0] }{ currentUser?.last_name[0] }</span>
													</span>
                                                    <span className="hidden ml-3 text-gray-700 text-sm font-medium lg:block">
                                                        <span className="sr-only">Open user menu for </span>{ currentUser?.first_name } { currentUser?.last_name }
                                                    </span>
                                                    <ChevronDownIcon
                                                        className="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block"
                                                        aria-hidden="true"
                                                    />
                                                </Menu.Button>
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-100">
                                                    <div className="px-4 py-3">
                                                        <p className="text-sm">Signed in as</p>
                                                        <p className="text-sm font-medium text-gray-900 truncate">{ currentUser?.email }</p>
                                                    </div>
                                                    <div className="py-1">
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <Link
                                                                    to="/account/orders"
                                                                    className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                                >
                                                                    Orders
                                                                </Link>
                                                            )}
                                                        </Menu.Item>
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <Link
																	to="/account/settings"
                                                                    className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                                >
                                                                    Settings
                                                                </Link>
                                                            )}
                                                        </Menu.Item>
                                                    </div>
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <a
                                                                href="#"
                                                                onClick={() => { _delivery_location.removeIdAndName(); AuthService.logout(); }}
                                                                className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                                            >
                                                                Logout
                                                            </a>
                                                        )}
                                                    </Menu.Item>
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>

                                        {/*<span className="h-6 w-px bg-gray-200 m-4" aria-hidden="true" />

                                        
                                        <Notification/>*/}
                                    </> :

                                    <div className="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
                                        <Link to="/guest/login" className="text-sm font-medium text-gray-700 hover:text-gray-800">Sign In</Link>
                                        <span className="h-6 w-px bg-gray-200" aria-hidden="true" />
                                        <Link to="/guest/register" className="text-sm font-medium text-gray-700 hover:text-gray-800">
                                            Create account
                                        </Link>
                                    </div>
                                }

								<span className="hidden lg:flex h-6 w-px bg-gray-200 m-4" aria-hidden="true" />

                                {/* Location */}
                                <Location />


                                <span className="h-6 w-px bg-gray-200 m-4" aria-hidden="true" />
                                {/* Search */}
                                <Search />


                                {/* Cart */}
                                <Cart />

                            </div>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    );
}