import { Outlet } from "react-router-dom";

import { useState, useEffect } from 'react';

import { NavBar } from './components/navbar';
import { Footer } from './components/footer';

import Progress from './libraries/progress/Progress';
import Alert from './libraries/alert';

import { ProgressBarEvents } from './common/progress.context';
import { AlertEvents } from './common/alert.context';

function App() {

	var _progress_events = ProgressBarEvents();
	var _alert_events = AlertEvents();

	// useEffect(() => {
	// 	setTimeout(() => {
	// 		var _new_alert = {
	// 			"id": "3",
	// 			"type": "info",
	// 			"title": "Alert 3",
	// 			"sub": "sub info 3"
	// 		};
	// 		_alert_events.add(_new_alert);
	// 	}, 5000);
	// },[]);

	return (
		<>
		<div id="container" className="bg-white">
			<NavBar/>
			<Progress isAnimating={_progress_events.status}/>

			<main className="mt-16">
			 	<Outlet/>
			</main>

			<Footer/>
			<Alert alerts={_alert_events.alerts} handleDelete={_alert_events.remove} />
		</div>
		</>
	)
}

export default App;
