import { Outlet } from "react-router-dom";

import { NavBar } from './components/navbar';
import { Footer } from './components/footer';

function Guest() {
	return (
		<div>
			<main className="mt-24">
			 	<Outlet/>
			</main>
            <footer aria-labelledby="footer-heading">
				<h2 id="footer-heading" className="sr-only">
					Footer
				</h2>
				<div className="max-w-full mx-auto px-4">
					<div className="mt-12 pt-8">
						<p className="text-base text-gray-400 xl:text-center">&copy; 2025 Adott, Inc. All rights reserved.</p>
					</div>
				</div>
			</footer>
		</div>
	)
}

export default Guest;
