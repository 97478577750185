import { useRef, useEffect } from 'react';

import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js';
import { createRedirectUrlPlugin } from '@algolia/autocomplete-plugin-redirect-url';
import { liteClient as algoliasearch } from 'algoliasearch/lite';

import '@algolia/autocomplete-theme-classic';
import { IMG_URL } from '../config';

const searchClient = algoliasearch('504971AJ5G', '3c85d324b8d486acd4776b8def2bfddf');

export function Autocomplete(props) {
	const containerRef = useRef(null);
	const panelRootRef = useRef(null);
	const rootRef = useRef(null);

	useEffect(() => {
		if (!containerRef.current) {
			return undefined;
		}

		const search = autocomplete({
			container: containerRef.current,
			placeholder: "Search",
			openOnFocus: true,
			plugins: [createRedirectUrlPlugin],
			getSources({ query }) {
				return [
					{
						sourceId: "demo-source",
						templates: {
							item(params) {
								const { item, html } = params;
								console.log(item);
								// return html`<a href=${"/product/"+item.uniqueId} class="aa-ItemLink">${item.title}</a>`;
								return html `<><a class="aa-ItemLink" href=${"/product/"+item.uniqueId}>
									<div class="flex size-10 flex-none items-center justify-center rounded-lg">
										<img src="${IMG_URL}/stores/store_1/${item.images[0]}" style="height: 60px;">
									</div>
									<div class="ml-4 flex-auto">
										<p class="text-sm font-medium text-gray-700">${item.title}</p>
                      					<p class="text-sm text-gray-500">
											${item.manufacturerBrand} / ${item.cat_name}
										</p>
									</div>
								</a></>`;
							}
						},
						getItemInputValue({ item }) {
							return item.name;
						},
						getItems() {
							return getAlgoliaResults({
								searchClient,
								queries: [
									{
										indexName: "search",
										query,
										params: {
											ruleContexts: ["enable-redirect-url"], // note: only needed for this demo data
											hitsPerPage: 10
										}
									}
								]
							});
						}
					}
				]
			}
		});

		return () => {
			search.destroy();
		};
	}, [props]);

	return <div ref={containerRef} />;
}

export function Search() {
	return (
		<Autocomplete />
	);
}