import React, {createContext, useState, useContext, useEffect} from 'react';
import store from 'store2';
import { dbCall } from './db';

const CartContext = createContext({});

const _cart_key = "cart";

const CartProvider = ({children}) => {
	const [currentItems, setCurrentItems] = useState([]);
	const [subTotal, setSubTotal] = useState(0);

	// on app load, check exists on local storage
	useEffect(() => {
		checkItems();
	},[]);

	useEffect(() => {
		var _total = 0;
		currentItems.map((product) => {
			var _item_total = product.qty * product.price;
			_total += _item_total;
		});

		_total = parseFloat(_total).toFixed(2);

		setSubTotal(_total);

		store.set(_cart_key, currentItems);
	},[currentItems]);

	// check
	async function checkItems() {
		if(store.has(_cart_key))
		{
			var _data = store.get(_cart_key);
			setCurrentItems(_data);
		}

		// console.log("checking existing cart");
	}

	function getLastParentName(item) {
		let current = item;
		while (current.parent !== null) {
			current = current.parent;
		}
		return current.name;
	}

	function getAlcoholValue(data)
	{
		var attributesArray = JSON.parse(data[0].attributes);
		var alcoholAttribute = attributesArray.find(attr => attr.name === "Alcohol Alc/Vol");
		var alcoholValue = alcoholAttribute ? parseFloat(alcoholAttribute.value) : null;

		return alcoholValue;
	}

	// add
	async function addItem(listing_id, variant_id = null) {
		// if item already exists update qty
		var _item_details = currentItems.find(o => o.id == listing_id + "_" + variant_id);
		if(_item_details)
		{
			var _existing_items = [...currentItems];
			// var _index = _existing_items.indexOf("1");
			var _index = _existing_items.findIndex(o => o.id == listing_id + "_" + variant_id);
			_existing_items[_index].qty = parseInt(_existing_items[_index].qty) + 1;

			setCurrentItems(_existing_items);
		}
		else
		{
			// check product and availability

			// fetch product info from database
			dbCall.get("products/listing_info/"+listing_id).then((response) => {
			
				// console.log(response);

				var _product_listing = response.data;

				var _variant_name = null;
				var _price = null;

				if(variant_id != null)
				{
					// console.log("get variant");

					// get variant
					var _variants = _product_listing.variantsInfo;
					var _variant = _variants.find(o => o.id == variant_id);
					
					_variant_name = _variant.name;
					_price = _variant.price;
				}
				else
				{
					_price = _product_listing.price;
				}

				var _parent_cat = getLastParentName(_product_listing.product.category.parent);
				var _alcohol_percentage = getAlcoholValue(_product_listing.product.attributes);

				var _item = {
					"id": listing_id + "_" + variant_id,
					"uniqueId": _product_listing.uniqueId,
					"productId": _product_listing.productId,
					"storeId": _product_listing.Store.uniqueId,
					
					"variantId": variant_id,
					"variantName": _variant_name,
					"price": _price,

					"title": _product_listing.product.title,
					"category": _product_listing.product.category.name,
					"qty": 1,
					"parent_name": _parent_cat,
					"alcohol_percentage": _alcohol_percentage,
					
					"available": _product_listing.available,
					"image": _product_listing.product.images[0],
					"link": "/product/"+_product_listing.product.uniqueId
				};
		
				setCurrentItems([...currentItems, _item]);

			}).catch((error) => {

			});
		}

	}

	// remove
	async function removeItem(id) {

		var _existing_items = [...currentItems];
		// var _index = _existing_items.indexOf("1");
		var _index = _existing_items.findIndex(o => o.id == id);
		if(_index !== -1)
		{
			_existing_items.splice(_index, 1);
			setCurrentItems(_existing_items);
		}
	}

	// changeQty
	async function changeQty(id, qty) {
		var _item_details = currentItems.find(o => o.id == id);
		if(_item_details)
		{
			var _existing_items = [...currentItems];
			// var _index = _existing_items.indexOf("1");
			var _index = _existing_items.findIndex(o => o.id == id);
			_existing_items[_index].qty = parseInt(qty);

			setCurrentItems(_existing_items);
		}
	}

	// item detail
	async function itemDetail(id) {
		var _item = currentItems.find(o => o.id == id);
		return _item;
	}

	// empty card
	async function emptyCart() {
		setCurrentItems([]);
	}

	// update

	const cart = {
		items: currentItems,
		total: subTotal,
		check: checkItems,
		add: addItem,
		changeQty: changeQty,
		remove: removeItem,
		detail: itemDetail,
		empty: emptyCart
	};

	return(
		<CartContext.Provider value={cart}>
			{children}
		</CartContext.Provider>
	)
}

function ShoppingCart() {
	const context = useContext(CartContext);

	if(!context) {
		throw new Error('must be within Provider');
	}

	return context;
}

export {CartContext, CartProvider, ShoppingCart};