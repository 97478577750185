export const navigation = {
    categories: [
        {
            id: 'beer',
            name: 'Beer',
            featured: [
                {
                    name: 'New Arrivals',
                    href: '/category/beer?sort=latest',
                    imageSrc: 'https://images.unsplash.com/photo-1568103185549-222675ad8bd0?auto=format&fit=crop&w=687&q=80',
                    imageAlt: 'Models sitting back to back, wearing Basic Tee in black and bone.',
                },
                {
                    name: 'Essentials',
                    href: '/category/beer?type=essentials',
                    imageSrc: 'https://images.unsplash.com/photo-1592415008610-0091df20779d?auto=format&fit=crop&w=1974&q=80',
                    imageAlt: 'Close up of Basic Tee fall bundle with off-white, ochre, olive, and black tees.',
                },
            ],
            sections: [
                {
                    id: 'styles',
                    name: 'Styles',
                    items: [
                        { name: 'IPA', href: '/category/ipa' },
                        { name: 'Lager', href: '/category/lager' },
                        { name: 'Light Beer', href: '#' },
                        { name: 'Ale', href: '/category/ale' },
                        { name: 'Sour Beer', href: '/category/sour-wild-ale' },
                        { name: 'Non-Alcohlic', href: '/category/non-alcoholic-beer' },
                        { name: 'Shop All', href: '/category/beer' }
                    ],
                },
                {
                    id: 'trending',
                    name: 'Trending',
                    items: [
                        // { name: 'Craft Beer', href: '/category/beer?type=craft' },
                        // { name: 'Variety Packs', href: '#' },
                        { name: 'Large Format', href: '/category/lager' },
                        { name: 'Gluten Format / Reduced', href: '/category/gluten-reduced-beer' },
                        // { name: 'Shop By Brand', href: '/brands?type=beer' }
                    ],
                },
                // {
                //     id: 'more-stuff',
                //     name: 'More Stuff',
                //     items: [
                //         { name: 'Blog', href: '/blog/posts?tag=beer' },
                //         { name: 'Pairing Guide', href: '/blog/posts?tag=beer+pairing-guide' },
                //         { name: 'Buying Guide', href: '/blog/posts?tag=beer+buying-guide' }
                //     ],
                // },
            ],
        },
        {
            id: 'wine',
            name: 'Wine',
            featured: [
                {
                    name: 'New Arrivals',
                    href: '/category/wine?sort=latest',
                    imageSrc: 'https://images.unsplash.com/photo-1611571940159-425a28706d6f?auto=format&fit=crop&w=765&q=80',
                    imageAlt: 'Drawstring top with elastic loop closure and textured interior padding.',
                },
                {
                    name: 'Popular',
                    href: '/category/wine?sort=popular',
                    imageSrc: 'https://images.unsplash.com/photo-1588982766898-4a826e5ef631?auto=format&fit=crop&w=687&q=80',
                    imageAlt:
                        'Three shirts in gray, white, and blue arranged on table with same line drawing of hands and shapes overlapping on front of shirt.',
                },
            ],
            sections: [
                {
                    id: 'red',
                    name: 'Red',
                    items: [
                        { name: 'Merlot', href: '/category/merlot' },
                        { name: 'Pinot Noir', href: '/category/pinot-noir' },
                        { name: 'Red Blend', href: '/category/red-blend' },
                        { name: 'Malbec', href: '/category/malbec' }
                    ],
                },
                {
                    id: 'white',
                    name: 'White',
                    items: [
                        { name: 'Pinot Grigio', href: '/category/pinot-grigio' },
                        { name: 'Moscato', href: '/category/moscato' },
                        { name: 'Riesling', href: '/category/riesling' },
                        { name: 'Chardonnay', href: '/category/chardonnay' }
                    ],
                },
                {
                    id: 'trending',
                    name: 'Trending',
                    items: [
						{ name: 'Shop All', href: '/category/wine' },
                        { name: 'Re-Under $10', href: '/category/wine?sort=under-10' },
                        { name: 'California', href: '/category/california-wine' },
                        { name: 'Italy', href: '/category/italian-wine' },
                        // { name: 'Shop By Brand', href: '/brands?type=wine' },
                    ],
                },
            ],
        },
        {
            id: 'spirits',
            name: 'Spirits',
            featured: [],
            sections: [
                {
                    id: 'types',
                    name: 'Types',
                    items: [
                        { name: 'Whiskey', href: '/category/whiskey' },
                        { name: 'Vodka', href: '/category/vodka' },
                        { name: 'Tequila', href: '/category/tequila' },
                        { name: 'Malbec', href: '/category/malbec' }
                    ],
                },
                {
                    id: 'more',
                    name: '',
                    items: [
                        { name: 'Gin', href: '/category/gin' },
                        { name: 'Rum', href: '/category/rum' },
                        { name: 'Brandy', href: '/category/brandy' },
                        { name: 'Shop All', href: '/category/spirits' }
                    ],
                },
                {
                    id: 'trending',
                    name: 'Trending',
                    items: [
                        { name: 'Flavored Vodka', href: '/category/flavored-vodka' },
                        { name: 'Mixers', href: '/category/bar-mixers' },
                        // { name: 'Shop By Brand', href: '/brands?type=spirits' },
                    ],
                },
            ],
        },
        {
            id: 'more',
            name: 'More',
            featured: [],
            sections: [
                {
                    id: 'cide',
                    name: 'Cider',
                    items: [
                        { name: 'Perry', href: '/category/perry' },
                        { name: 'Coolers & Carriers', href: '/category/coolers-carriers' }
                    ],
                },
                {
                    id: 'alberta',
                    name: 'Alberta Made',
                    items: [
                        { name: 'Beer', href: '/category/beer?tag=alberta-made' },
                        { name: 'Wine', href: '/category/wine?tag=alberta-made' },
                        { name: 'Spirits', href: '/category/spirits?tag=alberta-made' },
                        { name: 'Cider', href: '/category/cider?tag=alberta-made' }
                    ],
                }
            ],
        },
    ],
    pages: [
        // { name: 'For Business', href: '#' },
        // { name: 'Gifts', href: '#' },
        // { name: 'Rewards', href: '#' }
    ]
};