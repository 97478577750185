import React, {createContext, useState, useContext, useEffect} from 'react';

const ProgressContext = createContext({});

const ProgressProvider = ({children}) => {
	const [isRunning, setIsRunning ] = useState(false);

	async function startProgress() {
		setIsRunning(true);
	}

	async function stopProgress() {
		setIsRunning(false);
	}

	const progress = {
		status: isRunning,
		start: startProgress,
		stop: stopProgress
	};

	return(
		<ProgressContext.Provider value={progress}>
			{children}
		</ProgressContext.Provider>
	)
}

function ProgressBarEvents() {
	const context = useContext(ProgressContext);

	if(!context) {
		throw new Error('must be within Provider');
	}

	return context;
}

export {ProgressContext, ProgressProvider, ProgressBarEvents};