// production
export const APP_URL = "https://backend.adott.ca";
export const IMG_URL = "https://beta.adott.ca";
export const GOOGLE_CALLBACK_URL = "https://beta.adott.ca/guest/auth/google/callback";

// dev

// export const IMG_URL = "http://localhost:3000";
// export const APP_URL = "http://localhost:3001";
// export const GOOGLE_CALLBACK_URL = "http://localhost:3000/guest/auth/google/callback";


export const API_URL = APP_URL+"/api";
export const AUTH_URL = APP_URL+"/auth";