import axios from 'axios';
import { AuthService } from '../common/auth.service';
import { API_URL } from '../config';

const instance = axios.create();

instance.defaults.baseURL = API_URL;
if(AuthService.currentUserValue != null) {
	instance.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(AuthService.currentUserValue).token;
}

instance.defaults.headers.get['Accept'] = 'application/json';
instance.defaults.headers.post['Accept'] = 'application/json';
instance.defaults.onDownloadProgress = function (progressEvent) {
	// setShowProgressBar(true);
};
instance.defaults.onUploadProgress = function (progressEvent) {
	// setShowProgressBar(true);
};

instance.interceptors.request.use(function (request) {
	// setShowProgressBar(true);
	return request;
}, function (error) {
	console.log("unable to load");
});

instance.interceptors.response.use(function (response) {

	if (response.data.status === false) {
		// Error message is retrived from the JSON body.
		const error = new Error(response.data.message);
		// Attach the response instance, in case we would like to access it.
		error.response = response;
	
		throw error;
	  }

	if (response.status == "400") {
		const error = new Error(response.data.message);
		// Attach the response instance, in case we would like to access it.
		error.response = response;
	
		throw error;
	}

	if (response.status == "404") {
		console.log("s");
	}
	if (response.status == "401" || response.status == "302") {
		// unauthorized
		// redirect
		console.log("redirect");
	}

	if (response.status == 401)
	{
		console.log("redirect");
	}
	// setShowProgressBar(false);
	return response;
}, function (error) {
	// show alert
	// alert("redirect");

	if(error.response.status == 401)
	{
		if(error.response.data == "jwt expired")
		{
			AuthService.logout();
			// remove current session
			// redirect to login
			// alert("redirect");
		}
	}

	console.log(error.response);
	console.log(error.response.data);
	throw error.response.data;
	// setShowProgressBar(false);
});


export const dbCall = instance;