import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'

import { ShoppingBagIcon } from '@heroicons/react/outline';

import { useParams, Link } from "react-router-dom";

import { ShoppingCart } from '../common/cart.context';
import { IMG_URL } from '../config';

export function Cart() {

	const _cart = ShoppingCart();

    const [openCart, setOpenCart] = useState(false);

    return (
        <>
            <div className="ml-3 flow-root">
                <button type="button" className="group -m-2 p-2 flex items-center" onClick={() => setOpenCart(true)}>
                    {_cart.items.length > 0 ? 
					<>
					<ShoppingBagIcon
						className="flex-shrink-0 h-6 w-6 group-hover:text-gray-500 text-blue-500"
						aria-hidden="true"
					/>
					<span className="ml-2 text-sm font-medium group-hover:text-gray-800 text-blue-500">{_cart.items.length}</span>
					</>:
					<>
					<ShoppingBagIcon
                        className="flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                    />
					</>}
                    
                    <span className="sr-only">items in cart, view bag</span>
                </button>
            </div>

            <Transition.Root show={openCart} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 overflow-hidden z-20" onClose={setOpenCart}>
                    <div className="absolute inset-0 overflow-hidden">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-in-out duration-500"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in-out duration-500"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <div className="pointer-events-auto w-screen max-w-md">
                                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                                        <div className="flex-1 overflow-y-auto py-6 px-4 sm:px-6">
                                            <div className="flex items-start justify-between">
                                                <Dialog.Title className="text-lg font-medium text-gray-900"> Shopping cart </Dialog.Title>
                                                <div className="ml-3 flex h-7 items-center">
                                                    <button
                                                        type="button"
                                                        className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                                                        onClick={() => setOpenCart(false)}
                                                    >
                                                        <span className="sr-only">Close panel</span>
                                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="mt-8">
                                                <div className="flow-root">
                                                    <ul role="list" className="-my-6 divide-y divide-gray-200">
                                                        {_cart.items.map((product) => (
                                                            <li key={product.id} className="flex py-6">
                                                                <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                                                                    <img
                                                                        src={IMG_URL+"/stores/store_1/"+product.image}
                                                                        className="h-full w-full object-cover object-center"
                                                                    />
                                                                </div>

                                                                <div className="ml-4 flex flex-1 flex-col">
                                                                    <div>
                                                                        <div className="flex justify-between text-base font-medium text-gray-900">
                                                                            <h3>
                                                                                <Link to={product.link}>
																					{product.title}
																					{product.variantName ?
																						<span className="text-sm text-gray-600 ml-1">({product.variantName})</span>
																					: null }
																				</Link>
                                                                            </h3>
                                                                            <p className="w-16 text-right">$ {product.price}</p>
                                                                        </div>
                                                                        <p className="mt-1 text-sm text-gray-500">{product.category}</p>
                                                                    </div>
                                                                    <div className="flex flex-1 items-end justify-between text-sm">
                                                                        <p className="text-gray-500">Qty: {product.qty}</p>
																		

                                                                        <div className="flex">
                                                                            <button
																				type="button"
																				className="font-medium text-red-600 hover:text-red-500"
																				onClick={() => _cart.remove(product.id)}
																			>
                                                                                Remove
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
													{_cart.items.length == 0 ?
														<div className="mt-6 text-center">
															Wow, much empty
														</div>: null
													}
													
                                                </div>
                                            </div>
                                        </div>

										{_cart.items.length > 0 ?

                                        <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
                                            <div className="flex justify-between text-base font-medium text-gray-900">
                                                <p>Subtotal</p>
                                                <p>$ {_cart.total}</p>
                                            </div>
                                            <p className="mt-0.5 text-sm text-gray-500">Shipping and taxes calculated at checkout.</p>
                                            <div className="mt-6">
                                                <Link
                                                    to="/checkout"
                                                    className="flex items-center justify-center rounded-md border border-transparent bg-amber-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-amber-700"
													onClick={() => setOpenCart(false)}
                                                >
                                                    Checkout
                                                </Link>
                                            </div>
                                            <div className="mt-6 flex justify-center text-center text-sm text-gray-500">
                                                <p>
                                                    <Link
														to="/cart"
														className="font-medium text-amber-600 hover:text-amber-500 mr-1 hidden"
														onClick={() => setOpenCart(false)}
													>
														Review Items
													</Link>

													or{' '}
                                                    
													<button
                                                        type="button"
                                                        className="font-medium text-amber-600 hover:text-amber-500"
                                                        onClick={() => setOpenCart(false)}
                                                    >
                                                        Continue Shopping<span aria-hidden="true"> &rarr;</span>
                                                    </button>
                                                </p>
                                            </div>
                                        </div>
										:
										<div className="border-t border-gray-200 py-6 px-4 sm:px-6">
											<div className="flex justify-center text-center text-sm text-gray-500">
                                                <p> 
													<button
                                                        type="button"
                                                        className="font-medium text-indigo-600 hover:text-indigo-500"
                                                        onClick={() => setOpenCart(false)}
                                                    >
                                                        Continue Shopping<span aria-hidden="true"> &rarr;</span>
                                                    </button>
                                                </p>
                                            </div>
										</div>
										}
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
}