import React, {createContext, useState, useContext, useEffect} from 'react';

const AlertContext = createContext({});

const AlertProvider = ({children}) => {
	const [alerts, setAlerts] = useState([]);

	async function addAlert(alert) {
		setAlerts(oldData => [...oldData, alert]);
	}

	async function removeAlert(id) {
		setAlerts(oldAlerts => {
			return oldAlerts.filter(alert => alert.id != id)
		});
	}

	const progress = {
		alerts: alerts,
		add: addAlert,
		remove: removeAlert
	};

	return(
		<AlertContext.Provider value={progress}>
			{children}
		</AlertContext.Provider>
	)
}

function AlertEvents() {
	const context = useContext(AlertContext);

	if(!context) {
		throw new Error('must be within Provider');
	}

	return context;
}

export {AlertContext, AlertProvider, AlertEvents};